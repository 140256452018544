@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
    color: #4e4e4d;
    font-family: 'Gotham A', 'Gotham B', 'Open Sans', sans-serif;
}

h1, h2, h4, h4 span {
    color: #4e4e4d;
    font-size: 1.9rem;
    font-weight: 500;
}
h1 strong, h2 strong, h3 strong, h4 strong {
    color: #608da2;
    font-weight: 700;
}
h3 {
    color: #676767;
    margin-bottom: 1.5em !important;
    font-size: 1.6rem;
    font-weight: 500;
}
h4.sv_p_title, h4.sv_p_title span {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}
.member-title h2 {
    font-size: 1.3rem;
    line-height: 120%;
}
p {
    font-size: 0.9rem !important;
    font-weight: 400;
    line-height: 150%;
}
p a {
    color: #4e4e4d;
    font-weight: 500;
    text-decoration: underline;
    /*text-decoration: none;*/
}
p a:hover {
    color: #608da2;
}
ul li {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 150%;
}
.container.container-alt {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px !important;
    padding-left: 15px !important;
}
.row {
    --bs-gutter-x: 0 !important;
}
.member-title hr {
    display: inline-block;
    width: 66px;
    border: none;
    border-bottom: 5px solid #608da2;
    padding: 0px;
    opacity: 1;
}
.member-text p {
    width: 100%;
    color: #4e4e4d;
    margin-left: auto;
    margin-right: auto;
}
.separator {
    background-color: rgb(153,153,153);
    width: 107px;
    height: 2px;
    margin: 28px auto;
    display: block;
}
.member-button-link {
    font-family: 'Gotham A', 'Gotham B', Helvetica, Arial, Verdana, sans-serif;
    display: inline-block;
    color: #083147;
    font-size: 1em;
    font-weight: 700;
    width: 100%;
    border: 2px solid #608da2;
    background-color: transparent;
    padding: 0.75rem 2rem;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.member-button-link:hover {
    background-color: #608da2;
    color: #FFF;
}

.button-link, .sv-button-link {
    border: 1px solid rgb(127,127,127) !important;
    border-radius: 0 !important;
    color: #212529;
    width: 231px;
    margin-bottom: 15px;
    height: 58px;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-size: 13.5px !important;
    font-weight: bold !important;
    line-height: 1em;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}
.button-link {
    padding-top: 20px;
}
.button-link:hover {
    color: #212529;
}

@media screen and (min-width: 576px) {
    .member-title h2 {
        font-size: 1.5rem;
    }
    .row.row-alt .container {
        max-width: 540px;
    }
}
@media screen and (min-width: 768px) {
    .member-title h2 {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.9rem;
    }
    .member-text p {
        width: 70%;
    }
    .member-button-link {
        width: auto;
    }
}
@media screen and (min-width: 1400px) {
    .row.row-alt .container {
        max-width: 1320px;
    }
}
@media screen and (max-width: 576px) {
    .row > * {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}