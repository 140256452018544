@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
body {
    color: #4e4e4d;
    font-family: 'Gotham A', 'Gotham B', 'Open Sans', sans-serif;
}

h1, h2, h4, h4 span {
    color: #4e4e4d;
    font-size: 1.9rem;
    font-weight: 500;
}
h1 strong, h2 strong, h3 strong, h4 strong {
    color: #608da2;
    font-weight: 700;
}
h3 {
    color: #676767;
    margin-bottom: 1.5em !important;
    font-size: 1.6rem;
    font-weight: 500;
}
h4.sv_p_title, h4.sv_p_title span {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}
.member-title h2 {
    font-size: 1.3rem;
    line-height: 120%;
}
p {
    font-size: 0.9rem !important;
    font-weight: 400;
    line-height: 150%;
}
p a {
    color: #4e4e4d;
    font-weight: 500;
    text-decoration: underline;
    /*text-decoration: none;*/
}
p a:hover {
    color: #608da2;
}
ul li {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 150%;
}
.container.container-alt {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px !important;
    padding-left: 15px !important;
}
.row {
    --bs-gutter-x: 0 !important;
}
.member-title hr {
    display: inline-block;
    width: 66px;
    border: none;
    border-bottom: 5px solid #608da2;
    padding: 0px;
    opacity: 1;
}
.member-text p {
    width: 100%;
    color: #4e4e4d;
    margin-left: auto;
    margin-right: auto;
}
.separator {
    background-color: rgb(153,153,153);
    width: 107px;
    height: 2px;
    margin: 28px auto;
    display: block;
}
.member-button-link {
    font-family: 'Gotham A', 'Gotham B', Helvetica, Arial, Verdana, sans-serif;
    display: inline-block;
    color: #083147;
    font-size: 1em;
    font-weight: 700;
    width: 100%;
    border: 2px solid #608da2;
    background-color: transparent;
    padding: 0.75rem 2rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.5s;
}
.member-button-link:hover {
    background-color: #608da2;
    color: #FFF;
}

.button-link, .sv-button-link {
    border: 1px solid rgb(127,127,127) !important;
    border-radius: 0 !important;
    color: #212529;
    width: 231px;
    margin-bottom: 15px;
    height: 58px;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-size: 13.5px !important;
    font-weight: bold !important;
    line-height: 1em;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}
.button-link {
    padding-top: 20px;
}
.button-link:hover {
    color: #212529;
}

@media screen and (min-width: 576px) {
    .member-title h2 {
        font-size: 1.5rem;
    }
    .row.row-alt .container {
        max-width: 540px;
    }
}
@media screen and (min-width: 768px) {
    .member-title h2 {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.9rem;
    }
    .member-text p {
        width: 70%;
    }
    .member-button-link {
        width: auto;
    }
}
@media screen and (min-width: 1400px) {
    .row.row-alt .container {
        max-width: 1320px;
    }
}
@media screen and (max-width: 576px) {
    .row > * {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
h4 .sv-string-viewer {
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
}
h4.sv_p_title {
    margin-bottom: 1.75em;
    padding: 0 !important;
}
h4.sv_p_title .sv-string-viewer {
    margin-bottom: 0;
    font-size: 0.9rem;
    font-size: 1rem;
    line-height: 150%;
    text-transform: none;
}
.small {
    margin-bottom: 2.5em;
}
.small .sv-string-viewer {
    font-size: 0.9rem !important;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
}

/* Progress bar styles */
.sv_main.sv_main .progress {
    width: 100% !important;
    margin-bottom: 85px !important;
}
.progress {
    background-color: #e7e6e6 !important;
    height: 7px !important;
    margin-bottom: 60px;
    border-radius: 0 !important;
    position: relative;
    overflow: visible !important;
}
.progress-bar {
    background-color: #5a90a7 !important;
}
.sv-progress-text {
    color: #4e4e4d !important;
    position: absolute;
    margin-top: 24px;
    color: #9d9d9d;
    font-size: .87em;
    font-weight: bold;
    padding-left: .6321em;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 150%;
}
.sv_main .sv_header__text {
    display: inline !important;
}
.sv_main .sv-hidden.show-text {
    padding-left: 0;
    display: block !important;
}
.sv_main .sv-hidden.show-text.inbar {
    display: none !important;
}

/* Question styles */
.container-survey {
    padding-right: 15px !important;
    padding-left: 15px !important;
}
.sv_row {
    margin-bottom: 0.75em;
}
.sv_bootstrap_css .sv_row .sv_qstn {
    padding: 0;
}
.sv_p_container {
    border-top: 1px solid #dadada;
    padding-top: 2em;
    overflow: hidden;
}

.sv_p_container h4, .sv_p_container h4 span {
    text-align: left !important;
}
.sv_row .sv_row {
    margin-bottom: 1em;
}
.sv_row .sv_row:last-child {

}
.sv_row .sv_row h5 {
    margin-bottom: 0.85em;
    font-size: 0.9rem;
    font-weight: 400;
}
.sv_row .sv_row .small {
    margin-bottom: 0;
}
.sv-rating {
    margin-bottom: 1em;
}
.sv-rating {
    border-left: 1px solid #dadada;
    margin-top: -1px;
    padding-top: 1px;
}
.sv-rating .sv-rating__item {
    border-top: 1px solid #dadada;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    /*border-right: 0;*/
    width: 90px;
    height: 45px;
    margin-top: -1px;
    padding: 0 8px;
    line-height: 0.9rem;
    text-align: center;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}
.sv-rating .sv-rating__item.sv-rating__item--selected {
    background-color: #608da2;
    border: 1px solid #608da2;
    color: #FFF;
}
.sv-rating .sv-rating__item:hover {
    color: #608da2;
    cursor: pointer;
}
.sv-rating fieldset .sv-rating__item:last-child {
    border-right: 1px solid #dadada;
}
.sv-rating .sv_q_rating_item_text {
    color: #676767;
    font-size: 0.7rem;
    font-weight: 500;
    display: block;
    transition: all 0.5s;
}
.sv-rating .sv-rating__item.sv-rating__item--selected .sv_q_rating_item_text {
    color: #FFF;
}
.sv-rating .sv-rating__item:hover .sv_q_rating_item_text {
    color: #608da2;
}

/* Survey footer styles */
.panel-footer.card-footer {
    background-color: transparent;
    border-top: 1px solid #dadada;
    margin-bottom: 2.5em;
    padding: 1.75em 0 0;
    overflow: hidden;
}
.panel-footer.card-footer .sv-button-link {
    background-color: #608da2;
    color: #FFF;
    border: none !important;
    width: 160px;
    height: auto;
    margin: 0 0 10px;
    padding: 10px;
    font-size: 0.7rem;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.5s;
}
.panel-footer.card-footer .sv-button-link:hover {
    background-color: #083147;
}
.panel-footer.card-footer .sv-footer__prev-btn {
    float: left;
}
.panel-footer.card-footer .sv-footer__next-btn, .panel-footer.card-footer .sv-footer__complete-btn {
    margin-right: 0 !important;
    margin-left: 10px;
    float: right;
}
@media screen and (max-width: 600px) {
    .sv-rating .sv-rating__item {
        width: 33.333%;
    }
}
/*.row .results-heading {
    padding-left: 15px !important;
    padding-right: 15px !important;
}*/
.row .results-heading h3 {
    font-size: 1.6rem;
}
h5.icon-title, h5.statement-title {
    color: #676767;
    margin-bottom: 0.6em;
    padding-top: 9px;
    padding-bottom: 5px;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1em;
}
h5.icon-title {
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 26px auto;
    padding-left: 35px;
}
.icon-work {background-image: url(/static/media/icon_needs_work.041122b0.svg);}
.icon-improvement {background-image: url(/static/media/icon_for_improvement.7d596339.svg);}
.icon-great {background-image: url(/static/media/icon_doing_great.d0bab1ad.svg);}

/* Radar styles */
.res-radar-wrapper {
    width: 100%;
    max-width: 700px;
    padding-left: 15px;
    padding-right: 15px;
}
.res-radar-inner {
    width: 100%;
    height: auto;
}

/* Results table */
.results-infowrapper {
    border-bottom: 1px solid #dadada;
}
.results-inforow {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.result-rowheading h4 {
    color: #676767;
    border-bottom: 1px solid #dadada;
    margin-bottom: 0.75rem;
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
}
.dial-column img {
    max-width: 250px;
}
.result-rowinfo ul {
    padding-left: 1rem;
}
.result-rowinfo ul li {
    color: #676767;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    line-height: 150%;
}

/* Results key */
.results-keyrow {
    overflow: hidden;
}
.key-box {
    border: 1px solid #676767;
    width: 24px !important;
    height: 24px;
    display: inline-block;
    print-color-adjust: exact !important;
}
.key-box svg, .key-box svg rect {
    width: 100%;
    height: 100%;
}
.key-box svg {
    float: left;
}
.key-description {
    width: auto !important;
    margin-left: 0.5em;
    padding-top: 3px;
    font-size: 14px;
    display: inline-block;
}

/* Results grid */
.results-grid {
    border-bottom: 1px solid #dadada;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 0.5em;
    gap: 0.5em;
    padding-bottom: 2em;
}
.results-grid .grid-item h5 {
    min-height: 45px;
    padding-right: 10px;
}
.grid-item.odd {
    grid-column: 1/2;
}
.grid-item.even {
    grid-column: 2/2;
}
.grid-item.statement-item {
    grid-column: 1/5;
}
.grade-element {
    border: 1px solid #676767;
    height: 25px;
    print-color-adjust: exact !important;
}
.grade-element svg, .grade-element svg rect {
    width: 100%;
    height: 100%;
}
.grade-element svg {
    float: left;
}
.grade-great svg rect {
    fill: #753500;
    stroke: #753500;
}
.grade-moderate svg rect {
    fill: #9E4B06;
    stroke: #9E4B06;
}
.grade-some svg rect {
    fill: #DE853B;
    stroke: #DE853B;
}
.grade-little svg rect {
    fill: rgba(192, 101, 25, 0.25);
    stroke: rgba(192, 101, 25, 0.25);
}
.grade-none svg rect {
    fill: #fff;
    stroke: #fff;
}
.no-printbreak, .no-printbreak .statement-title, .no-printbreak .statement-title em {
    overflow: visible !important;
    display: block !important;
}


/* Results subtext */
.results-subtext {
    border-top: 1px solid #dadada;
    padding-top: 2rem;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.results-subtext ul {
    padding-left: 2rem;
}

@media screen and (min-width: 992px) {
    .dial-column.text-center {
        text-align: left !important;
    }
}
@media screen and (min-width: 768px) {
    .row .results-heading, .results-inforow {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .res-radar-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
}
@media screen and (min-width: 600px) {
    .results-grid {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .grid-item.odd {
        grid-column: inherit;
    }
    .grid-item.even {
        grid-column: inherit;
    }
    .grid-item.statement-item {
        grid-column: inherit;
    }
}

@media print {
    .member-title hr {
        margin: 0;
    }
    .container.container-alt, .member-title h2, .member-text p {
        width: 100%;
        max-width: none;
    }
    .member-text.pb-5 {
        padding-bottom: 1rem !important;
    }
    .row .results-heading h3 {
        display: block;
    }
    .d-flex {
        display: block !important;
    }
    .col-md-6 {
        width: 100% !important;
    }
    .res-radar-wrapper {
        width: 100%;
        max-width: none;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }
    .res-radar-inner {
        width: 100% !important;
    }
    .res-radar-inner canvas {
        height: 65% !important;
        width: 65% !important;
        margin: 0 auto;
    }
    .container.container-alt.mb-4 {
        margin-bottom: 0 !important;
    }
    .row.row-alt {
        page-break-after: always;
        -webkit-column-break-after: page;
                break-after: page;
        position: relative;
    }
    .results-infowrapper {
        /*break-inside: page;
        display: table;*/
        border-bottom: none;
        position: relative;
    }
    .duty-wrapper {
        padding-bottom: 100px;
        overflow: hidden !important;
    }
    .results-infowrapper.mb-4 {
        margin-bottom: 0rem !important;
    }
    .results-inforow {
        width: 100%;
        display: block;
        page-break-inside: avoid !important;
        -webkit-column-break-inside: avoid !important;
                break-inside: avoid !important;
        position: relative;
        overflow: hidden;
    }
    .results-inforow.mb-5 {
        margin-bottom: 2rem !important;
    }
    .results-inforow .result-rowheading {
        page-break-before: always;
        -webkit-column-break-before: page;
                break-before: page;
    }
    .results-inforow .result-rowheading, .results-inforow .result-rowinfo {
        width: 100%;
        display: table;
        position: relative;
    }
    .results-inforow .result-rowinfo {
        overflow: hidden;
    }
    .results-inforow .result-rowinfo div {
        display: inline-block;
        vertical-align: top;
    }
    .results-subtext {
        border-top: 1px solid #dadada;
        margin-top: 2rem;
        padding: 1.75rem 0 1rem;
    }
    .col-lg-3 {
        flex: 0 0 auto !important;
        width: 26% !important;
        padding-left: 2%;
    }
    .col-lg-3.pe-md-5 {
        flex: 0 0 auto !important;
        width: 29.333% !important;
        padding-left: 2% !important;
        padding-right: 0 !important;
    }
    .col-lg-3.dial-column {
        width: 12% !important;
        padding-right: 2%;
        padding-left: 0;
    }
    .dial-column.text-center {
        text-align: left !important;
    }
    h5.icon-title {
        padding-left: 1rem;
    }
    .member-button, .hideforprint {
        display: none;
    }
    .results-grid {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .grid-item.odd {
        grid-column: inherit;
    }
    .grid-item.even {
        grid-column: inherit;
    }
    .grid-item.statement-item {
        grid-column: inherit;
    }
}
