/*.row .results-heading {
    padding-left: 15px !important;
    padding-right: 15px !important;
}*/
.row .results-heading h3 {
    font-size: 1.6rem;
}
h5.icon-title, h5.statement-title {
    color: #676767;
    margin-bottom: 0.6em;
    padding-top: 9px;
    padding-bottom: 5px;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1em;
}
h5.icon-title {
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 26px auto;
    padding-left: 35px;
}
.icon-work {background-image: url('../img/icon_needs_work.svg');}
.icon-improvement {background-image: url('../img/icon_for_improvement.svg');}
.icon-great {background-image: url('../img/icon_doing_great.svg');}

/* Radar styles */
.res-radar-wrapper {
    width: 100%;
    max-width: 700px;
    padding-left: 15px;
    padding-right: 15px;
}
.res-radar-inner {
    width: 100%;
    height: auto;
}

/* Results table */
.results-infowrapper {
    border-bottom: 1px solid #dadada;
}
.results-inforow {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.result-rowheading h4 {
    color: #676767;
    border-bottom: 1px solid #dadada;
    margin-bottom: 0.75rem;
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
}
.dial-column img {
    max-width: 250px;
}
.result-rowinfo ul {
    padding-left: 1rem;
}
.result-rowinfo ul li {
    color: #676767;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    line-height: 150%;
}

/* Results key */
.results-keyrow {
    overflow: hidden;
}
.key-box {
    border: 1px solid #676767;
    width: 24px !important;
    height: 24px;
    display: inline-block;
    print-color-adjust: exact !important;
}
.key-box svg, .key-box svg rect {
    width: 100%;
    height: 100%;
}
.key-box svg {
    float: left;
}
.key-description {
    width: auto !important;
    margin-left: 0.5em;
    padding-top: 3px;
    font-size: 14px;
    display: inline-block;
}

/* Results grid */
.results-grid {
    border-bottom: 1px solid #dadada;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5em;
    padding-bottom: 2em;
}
.results-grid .grid-item h5 {
    min-height: 45px;
    padding-right: 10px;
}
.grid-item.odd {
    grid-column: 1/2;
}
.grid-item.even {
    grid-column: 2/2;
}
.grid-item.statement-item {
    grid-column: 1/5;
}
.grade-element {
    border: 1px solid #676767;
    height: 25px;
    print-color-adjust: exact !important;
}
.grade-element svg, .grade-element svg rect {
    width: 100%;
    height: 100%;
}
.grade-element svg {
    float: left;
}
.grade-great svg rect {
    fill: #753500;
    stroke: #753500;
}
.grade-moderate svg rect {
    fill: #9E4B06;
    stroke: #9E4B06;
}
.grade-some svg rect {
    fill: #DE853B;
    stroke: #DE853B;
}
.grade-little svg rect {
    fill: rgba(192, 101, 25, 0.25);
    stroke: rgba(192, 101, 25, 0.25);
}
.grade-none svg rect {
    fill: #fff;
    stroke: #fff;
}
.no-printbreak, .no-printbreak .statement-title, .no-printbreak .statement-title em {
    overflow: visible !important;
    display: block !important;
}


/* Results subtext */
.results-subtext {
    border-top: 1px solid #dadada;
    padding-top: 2rem;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.results-subtext ul {
    padding-left: 2rem;
}

@media screen and (min-width: 992px) {
    .dial-column.text-center {
        text-align: left !important;
    }
}
@media screen and (min-width: 768px) {
    .row .results-heading, .results-inforow {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .res-radar-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
}
@media screen and (min-width: 600px) {
    .results-grid {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .grid-item.odd {
        grid-column: inherit;
    }
    .grid-item.even {
        grid-column: inherit;
    }
    .grid-item.statement-item {
        grid-column: inherit;
    }
}

@media print {
    .member-title hr {
        margin: 0;
    }
    .container.container-alt, .member-title h2, .member-text p {
        width: 100%;
        max-width: none;
    }
    .member-text.pb-5 {
        padding-bottom: 1rem !important;
    }
    .row .results-heading h3 {
        display: block;
    }
    .d-flex {
        display: block !important;
    }
    .col-md-6 {
        width: 100% !important;
    }
    .res-radar-wrapper {
        width: 100%;
        max-width: none;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }
    .res-radar-inner {
        width: 100% !important;
    }
    .res-radar-inner canvas {
        height: 65% !important;
        width: 65% !important;
        margin: 0 auto;
    }
    .container.container-alt.mb-4 {
        margin-bottom: 0 !important;
    }
    .row.row-alt {
        break-after: page;
        position: relative;
    }
    .results-infowrapper {
        /*break-inside: page;
        display: table;*/
        border-bottom: none;
        position: relative;
    }
    .duty-wrapper {
        padding-bottom: 100px;
        overflow: hidden !important;
    }
    .results-infowrapper.mb-4 {
        margin-bottom: 0rem !important;
    }
    .results-inforow {
        width: 100%;
        display: block;
        break-inside: avoid !important;
        position: relative;
        overflow: hidden;
    }
    .results-inforow.mb-5 {
        margin-bottom: 2rem !important;
    }
    .results-inforow .result-rowheading {
        break-before: page;
    }
    .results-inforow .result-rowheading, .results-inforow .result-rowinfo {
        width: 100%;
        display: table;
        position: relative;
    }
    .results-inforow .result-rowinfo {
        overflow: hidden;
    }
    .results-inforow .result-rowinfo div {
        display: inline-block;
        vertical-align: top;
    }
    .results-subtext {
        border-top: 1px solid #dadada;
        margin-top: 2rem;
        padding: 1.75rem 0 1rem;
    }
    .col-lg-3 {
        flex: 0 0 auto !important;
        width: 26% !important;
        padding-left: 2%;
    }
    .col-lg-3.pe-md-5 {
        flex: 0 0 auto !important;
        width: 29.333% !important;
        padding-left: 2% !important;
        padding-right: 0 !important;
    }
    .col-lg-3.dial-column {
        width: 12% !important;
        padding-right: 2%;
        padding-left: 0;
    }
    .dial-column.text-center {
        text-align: left !important;
    }
    h5.icon-title {
        padding-left: 1rem;
    }
    .member-button, .hideforprint {
        display: none;
    }
    .results-grid {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .grid-item.odd {
        grid-column: inherit;
    }
    .grid-item.even {
        grid-column: inherit;
    }
    .grid-item.statement-item {
        grid-column: inherit;
    }
}