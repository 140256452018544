h4 .sv-string-viewer {
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
}
h4.sv_p_title {
    margin-bottom: 1.75em;
    padding: 0 !important;
}
h4.sv_p_title .sv-string-viewer {
    margin-bottom: 0;
    font-size: 0.9rem;
    font-size: 1rem;
    line-height: 150%;
    text-transform: none;
}
.small {
    margin-bottom: 2.5em;
}
.small .sv-string-viewer {
    font-size: 0.9rem !important;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
}

/* Progress bar styles */
.sv_main.sv_main .progress {
    width: 100% !important;
    margin-bottom: 85px !important;
}
.progress {
    background-color: #e7e6e6 !important;
    height: 7px !important;
    margin-bottom: 60px;
    border-radius: 0 !important;
    position: relative;
    overflow: visible !important;
}
.progress-bar {
    background-color: #5a90a7 !important;
}
.sv-progress-text {
    color: #4e4e4d !important;
    position: absolute;
    margin-top: 24px;
    color: #9d9d9d;
    font-size: .87em;
    font-weight: bold;
    padding-left: .6321em;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 150%;
}
.sv_main .sv_header__text {
    display: inline !important;
}
.sv_main .sv-hidden.show-text {
    padding-left: 0;
    display: block !important;
}
.sv_main .sv-hidden.show-text.inbar {
    display: none !important;
}

/* Question styles */
.container-survey {
    padding-right: 15px !important;
    padding-left: 15px !important;
}
.sv_row {
    margin-bottom: 0.75em;
}
.sv_bootstrap_css .sv_row .sv_qstn {
    padding: 0;
}
.sv_p_container {
    border-top: 1px solid #dadada;
    padding-top: 2em;
    overflow: hidden;
}

.sv_p_container h4, .sv_p_container h4 span {
    text-align: left !important;
}
.sv_row .sv_row {
    margin-bottom: 1em;
}
.sv_row .sv_row:last-child {

}
.sv_row .sv_row h5 {
    margin-bottom: 0.85em;
    font-size: 0.9rem;
    font-weight: 400;
}
.sv_row .sv_row .small {
    margin-bottom: 0;
}
.sv-rating {
    margin-bottom: 1em;
}
.sv-rating {
    border-left: 1px solid #dadada;
    margin-top: -1px;
    padding-top: 1px;
}
.sv-rating .sv-rating__item {
    border-top: 1px solid #dadada;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    /*border-right: 0;*/
    width: 90px;
    height: 45px;
    margin-top: -1px;
    padding: 0 8px;
    line-height: 0.9rem;
    text-align: center;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}
.sv-rating .sv-rating__item.sv-rating__item--selected {
    background-color: #608da2;
    border: 1px solid #608da2;
    color: #FFF;
}
.sv-rating .sv-rating__item:hover {
    color: #608da2;
    cursor: pointer;
}
.sv-rating fieldset .sv-rating__item:last-child {
    border-right: 1px solid #dadada;
}
.sv-rating .sv_q_rating_item_text {
    color: #676767;
    font-size: 0.7rem;
    font-weight: 500;
    display: block;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.sv-rating .sv-rating__item.sv-rating__item--selected .sv_q_rating_item_text {
    color: #FFF;
}
.sv-rating .sv-rating__item:hover .sv_q_rating_item_text {
    color: #608da2;
}

/* Survey footer styles */
.panel-footer.card-footer {
    background-color: transparent;
    border-top: 1px solid #dadada;
    margin-bottom: 2.5em;
    padding: 1.75em 0 0;
    overflow: hidden;
}
.panel-footer.card-footer .sv-button-link {
    background-color: #608da2;
    color: #FFF;
    border: none !important;
    width: 160px;
    height: auto;
    margin: 0 0 10px;
    padding: 10px;
    font-size: 0.7rem;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.panel-footer.card-footer .sv-button-link:hover {
    background-color: #083147;
}
.panel-footer.card-footer .sv-footer__prev-btn {
    float: left;
}
.panel-footer.card-footer .sv-footer__next-btn, .panel-footer.card-footer .sv-footer__complete-btn {
    margin-right: 0 !important;
    margin-left: 10px;
    float: right;
}
@media screen and (max-width: 600px) {
    .sv-rating .sv-rating__item {
        width: 33.333%;
    }
}